import Claim from "./components/Claim/Claim";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <>
      <Navbar />
      <Claim />
    </>
  );
}

export default App;
