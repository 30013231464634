import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import Mint from "../Mint/Mint";
import "./styles.css";

function Claim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    console.log(blockchain);
    console.log(data);
    //dispatch(currentWallet());
  }, [blockchain.account]);

  return (
    <>
      {blockchain.errorMsg !== "" ? <>{alert(blockchain.errorMsg)}</> : null}
      {blockchain.loading === true ? (
        <>
          <header className="loading-head">
            <div className="loading">
              <img
                src="/assets/img/CrypTotems.gif"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "30px",
                }}
                alt="loading"
              />
              <h2 className="text-center mt-5">loading...</h2>
            </div>
          </header>
        </>
      ) : (
        <header className="claim-head">
          {blockchain.account !== null ? (
            <Mint blockchain={blockchain} CONFIG={CONFIG} data={data} />
          ) : (
            <section className="claim-card">
              <div className="col-lg-12 text-white mx-auto ">
                <div className="claim-title">
                  <h1 className="text-center">PUBLIC SALE IS LIVE!</h1>
                  <h2>
                    Please review our{" "}
                    <a
                      className="text-white"
                      target="_blank"
                      href="https://www.youtube.com/watch?v=mdB1RKQ_e2Y&t=221s"
                    >
                      Minting Guide
                    </a>{" "}
                    for detailed instructions
                  </h2>
                  <br />
                  <div className="details">
                    <p className="mt-0">
                      1. You can mint up to 2 totems in one transaction. For
                      more, mint again.
                    </p>
                    <p className="mt-0">
                      2. Connect your wallet and choose the number of NFTs you
                      want to mint.
                    </p>
                    <p className="mt-0">
                      3. If the gas fee looks unusually high, YOU'RE DOING
                      SOMETHING WRONG!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-white text-center mx-auto claim-button">
                <div className="mt-4">
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT WALLET
                  </button>
                </div>
              </div>
            </section>
          )}
        </header>
      )}
    </>
  );
}

export default Claim;
