import React from "react";
// import "./styles.css";
import { AiFillMediumCircle } from "react-icons/ai";

function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top"
      id="mainNav"
    >
      <div className="container px-4">
        <a className="navbar-brand totem-logo" href="#root">
          CRYPTOTEMS
        </a>
        <button
          className="navbar-toggler navbar-toggler-right custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto my-2 my-lg-0">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://twitter.com/CrypTotemsNFT"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href="https://discord.com/invite/sF48H8b3fS"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-discord discord"></i>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.youtube.com/channel/UCLF9V3ASv-mYrswB47p3LCw?sub_confirmation=1"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://cryptotems.medium.com"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-medium"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://github.com/CrypTotems"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="nav-item"></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
