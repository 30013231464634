import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../redux/data/dataActions";
import "./styles.css";

const truncate = (input, len) =>
  input.length > len
    ? `${input.substring(0, len)}...${input.slice(input.length - len)}`
    : input;

function Mint({ blockchain, data, CONFIG }) {
  const dispatch = useDispatch();
  const [mintcount, setMintCount] = useState(1);
  const [quantity, setQuantity] = useState(2);
  const [feedback, setFeedback] = useState(``);
  const [claimingNft, setClaimingNft] = useState(false);

  const ValidateMintCount = () => {
    if (mintcount <= quantity) {
      return (
        <div>
          <h2 className="mt-2 " disabled>
            {mintcount == 0 ? setMintCount(1) : mintcount}
          </h2>
        </div>
      );
    } else {
      setMintCount(quantity);
      return (
        <div>
          <h2 className="mt-2 " disabled>
            {mintcount}
          </h2>
        </div>
      );
    }
  };

  const ButtonControl = () => {
    return (
      <div className="col-md-6 col-sm-12 mx-auto mint-count">
        <h2 className="mt-3">Mint Totems</h2>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-dark me-2 btn-control"
            onClick={() =>
              mintcount == 0 ? setMintCount(1) : setMintCount(mintcount - 1)
            }
          >
            -
          </button>
          <h2 className="mt-2 " disabled>
            <ValidateMintCount />
          </h2>
          <button
            className="btn btn-dark ms-2 btn-control"
            onClick={() => setMintCount(mintcount + 1)}
          >
            +
          </button>
        </div>
      </div>
    );
  };

  const claimNFTs = () => {
    console.log(CONFIG);
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintcount);
    console.log("Cost: ", totalCostWei);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintcount)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  return (
    <section className="mint-section">
      <div className=" text-white mx-auto mint-card">
        <div className="card">
          <div className="card-header">
            <div className="mint-logo text-center">
              <img src="/assets/img/chief-totem.jpg" />
              <h6 className="text-centerm mt-2">
                {blockchain.account != null
                  ? truncate(blockchain.account, 5)
                  : window.location.reload()}
              </h6>
            </div>
          </div>
          {data.loading ? (
            <>
              <div className="card-body">
                <h5>Loading...</h5>
              </div>
            </>
          ) : (
            <>
              {claimingNft ? (
                <>
                  <div className="card-body">
                    <h5>Minting...</h5>
                  </div>
                </>
              ) : (
                <>
                  <div className="card-body">
                    <ButtonControl />
                    <div className="col-md-12 col-sm-12 mx-auto">
                      <div className="d-flex justify-content-center">
                        <h2 className="mt-2 me-5" disabled>
                          Mint Price
                        </h2>
                        <h2 className="mt-2 " disabled>
                          0.04 ETH
                        </h2>
                      </div>
                    </div>
                    <h5 className="mt-2">
                      {data.totalSupply}/{data.maxSupply}
                    </h5>
                  </div>
                  <div className="card-footer">
                    <button
                      className="btn btn-primary btn-lg btn-mint mb-2"
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                      }}
                    >
                      MINT
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Mint;
