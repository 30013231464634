// log
import store from "../store";
import { ethers } from "ethers";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOfTickets()
        .call({ from: account });

      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call({ from: account });

      console.log("Max :" + maxSupply);
      balanceOf = parseInt(ethers.utils.formatEther(balanceOf));
      dispatch(
        fetchDataSuccess({
          totalSupply,
          balanceOf,
          account,
          maxSupply,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
